import { mapService } from '../services/MapService';

export const MAP_MAX_ZOOM = 18.4;
export const MAP_MIN_ZOOM = 0;
export const MAP_MAX_FIT_ZOOM = 17;

export const MAX_LNG = 75;
export const MAX_LAT = 180;
export const MIN_LAT = 0;

export const INITIAL_BBOX = [20, 42.5, 190.0, 70];

export const MAX_HEX_ZOOM = 10;
export const MIN_HEX_ZOOM = 2;

export const MAX_HEZTMAP_HEX_ZOOM = 9;

export const CUSTOM_WORKPLACE_STORAGE_KEY = 'custom workplaces';

// -4 подобрано на глаз
export const mapZoomToHexZoom = (mapZoom) => Math.max(MIN_HEX_ZOOM, Math.min(MAX_HEX_ZOOM, Math.floor(mapZoom) - 4));
export const mapZoomToHeatmapHexZoom = (mapZoom) => Math.min(MAX_HEZTMAP_HEX_ZOOM, mapZoomToHexZoom(mapZoom));

export const VERSION_INJECT_TOKEN = 'v';

export const SOURCES = {
  workplaces: 'workplaces_source',
  custom_workplaces: 'custom_workplaces_source',
  heatmap: 'heatmap_source',
  hex: 'hex_source',
};

export const LAYERS = {
  hex: 'hex_layer',
  heatmap: 'heatmap_layer',

  workpaces: 'workplaces_layer',
  custom_workpaces: 'custom_workpaces_layer',
};

export const getTypeObject = (key, isHeatmapHex = true, state) => {
  return {
    key,
    isHeatmapHex,
    text: state.rawHexTypes[key],
  };
};

export const getRegionObject = (regionId, state) => {
  return {
    id: regionId,
    name: state.regions[regionId],
  };
};
export const getTownObject = (regionId, townId, state) => {
  return {
    id: townId,
    name: state.towns[regionId].data[townId],
  };
};

export const dictToList = (data, getObjectFunction) =>
  Object.keys(data)
    .map(getObjectFunction)
    .sort((first, last) => (first.name > last.name ? 1 : -1));

export const cleanParams = (params) => {
  const newParams = { ...params };
  for (let key in newParams) {
    if (!newParams[key]) {
      delete newParams[key];
    }
  }

  return newParams;
};

export const fitBbox = (data) => {
  if (data?.length === 4) {
    const bbox = [
      [data[0], data[1]],
      [data[2], data[3]],
    ];

    mapService.map.fitBounds(bbox, {
      padding: { top: 50, bot: 10 },
      linear: true,
      maxZoom: MAP_MAX_FIT_ZOOM,
    });
  }
};

export const fixLatToPositive = (lat) => {
  if (lat > 0) return lat;
  return 360 + lat;
};

export const fixLatBounds = (lat) => {
  if (lat > 0) {
    return Math.min(MAX_LAT, lat);
  }
  if (lat < 0) {
    return Math.max(MIN_LAT, lat);
  }
  return 0;
};

export const fixLngBounds = (lng) => {
  if (lng > 0) {
    return Math.min(MAX_LNG, lng);
  }
  if (lng < 0) {
    return Math.max(-MAX_LNG, lng);
  }
  return 0;
};
