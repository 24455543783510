import { browserTracingIntegration, init, replayIntegration } from '@sentry/vue';
import $ from 'jquery';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import { createApp } from 'vue';
import * as v from '../version.json';
import App from './App.vue';
import { env } from './env/env';
import { configPrime } from './core/prime';
import { VERSION_INJECT_TOKEN } from '@/core/utils';
import { store } from './store/store';

window.$ = $;

const app = createApp(App);

// В процессе разработки сентри не нужна
if (!env.dev) {
  init({
    app,
    dsn: 'https://7b8ef261b1f88bc57d88fe17ca6a0464@sentry2.promsoft.ru/105',
    integrations: [browserTracingIntegration({}), replayIntegration()],
    tracePropagationTargets: ['localhost', env.baseUrl],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.provide(VERSION_INJECT_TOKEN, v.version);

app.use(store);

app.use(VCalendar, {});
configPrime(app);

app.mount('#app');
