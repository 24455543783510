import { env } from '@/env/env';
import axios from 'axios';
import { cleanParams } from '@/core/utils';
import { mapService } from './MapService';

class HttpService {
  constructor() {
    const params = new URLSearchParams(location.search);
    let token = params.get('token');

    this.axios = axios.create({
      baseURL: env.baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }

  get(url, config) {
    return this.axios.get(url, config).catch(console.log);
  }

  hexPost(url, params, config = {}) {
    return this.axios
      .post(
        url,
        cleanParams({
          ...params,
          filters: cleanParams(window?.store?.getters?.filters),
        }),
        config
      )
      .catch(console.log);
  }

  post(url, params, config = {}) {
    return this.axios
      .post(
        url,
        cleanParams({
          ...params,
          ...window?.store?.getters?.filters,
        }),
        config
      )
      .catch(console.log);
  }

  uploadPost(file) {
    const formData = new FormData();
    formData.append('inp', file);
    return this.axios
      .post(env.api.custom_workplaces, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(console.log);
  }

  search(query) {
    const center = mapService.map.getCenter();
    return this.axios
      .post(env.api.dadata, {
        query,
        locations_geo: [
          {
            lat: center.lat,
            lon: center.lng,
            radius_meters: 200_000,
          },
        ],
      })
      .catch(console.log);
  }
}

export const httpService = new HttpService();
