import { env } from '@/env/env';
import { httpService } from '@/services/HttpService';
import { hookAllFilters } from '@/services/UpdateHooks';

export const mFetchProjectGeoups = (state) => {
  httpService.post(env.api.groups_projects, {}).then(({ data }) => {
    state.projectGroups = data;
  });

  const id = state.currentProjectGroup?.id;
  if (id) {
    httpService.post(`${env.api.projects}${id}/data`, {}).then(({ data }) => {
      state.projects[id] = data;
    });
  }
};

export const mSetCurrentProjectGroup = (state, id) => {
  state.currentProjectGroup = state.projectGroups.find((pg) => pg.id === id);
  hookAllFilters();
  state.currentProject = null;

  if (!state.projects[id]) {
    httpService.post(`${env.api.projects}${id}/data`, {}).then(({ data }) => {
      state.projects[id] = data;
    });
  }
};

export const mResetCurrentProjectGroup = (state) => {
  state.currentProjectGroup = null;
  state.currentProject = null;
  hookAllFilters();
};

export const mSetCurrentProject = (state, id) => {
  state.currentProject = state.projects[state.currentProjectGroup.id].find((p) => p.id === id);
  hookAllFilters();
};
export const mResetCurrentProject = (state) => {
  state.currentProject = null;
  hookAllFilters();
};
