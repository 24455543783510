<template>
  <!-- <HeaderComponent class="z-index__header"></HeaderComponent> -->

  <TokenModal></TokenModal>

  <div class="z-index__search">
    <SearchComponent></SearchComponent>
  </div>

  <div class="content" ref="containerRef">
    <div class="w-full h-full d-flex flex-column-reverse">
      <div class="map" ref="mapRef"></div>
      <div class="z-index__filter">
        <FilterComponent></FilterComponent>
      </div>

      <div class="z-index__legend bg-light">
        <HeatmapLegendComponent></HeatmapLegendComponent>
      </div>
    </div>

    <TableComponent
      :list="store.state.tableData"
      :fields="store.state.tableFields"
      class="z-index__table bg-light"
      @selected="selectItem"
      @onDividerInit="initDivider"
    ></TableComponent>
  </div>
</template>

<script setup>
import FilterComponent from '@/components/FilterComponent.vue';
import HeatmapLegendComponent from '@/components/LegendComponent.vue';
import TokenModal from '@/components/modals/TokenModal.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import TableComponent from '@/components/TableComponent.vue';

import { hexService } from '@/services/HexService.js';
import { mapService } from '@/services/MapService.js';
import { hexUpdate$ } from '@/services/UpdateHooks';

import { LAYERS } from '@/core/utils';
import { ACTION_INIT_AFTER_MAP, ACTION_INIT_BEFORE_MAP, MUTATION_FETCH_LEGEND } from '@/store/store';
import { Popup } from 'maplibre-gl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const mapRef = ref(null);
const containerRef = ref(null);

let currentPopup;

const hexClickCallback = (e) => {
  currentPopup?.remove();

  currentPopup = new Popup()
    .setLngLat(e.lngLat)
    .setHTML(`${store.state.currentHexType.text}: ${e.features[0].properties.weight}`)
    .addTo(mapService.map);
};

const workplaceClickCallback = (e) => {
  currentPopup?.remove();

  const data = { ...e.features[0].properties };
  let result = '';

  store.state.tableFields.forEach((field) => {
    if (data[field]) {
      result += `<div>${field}: ${data[field]}</div>`;
    } else {
      result += `<div>${field}: 0</div>`;
    }
  });

  currentPopup = new Popup()
    .setLngLat(e.lngLat)
    .setHTML(result + `<div>ID: ${data.id}</div>`)
    .addTo(mapService.map);
};

// hooks

onMounted(() => {
  mapService.initMap(mapRef.value);
  store.dispatch(ACTION_INIT_BEFORE_MAP);

  mapService.map.on('load', () => {
    store.dispatch(ACTION_INIT_AFTER_MAP);
    hexService.init(mapService.map);

    let prevZoom = mapService.map.getZoom();
    mapService.map.on('zoomend', () => {
      const zoom = mapService.map.getZoom();

      if (Math.floor(prevZoom) !== Math.floor(zoom)) {
        currentPopup?.remove();
        store.commit(MUTATION_FETCH_LEGEND, Math.floor(zoom));
      }

      prevZoom = zoom;
    });

    mapService.map.on('click', LAYERS.hex, hexClickCallback);
    mapService.map.on('click', LAYERS.workpaces, workplaceClickCallback);
    mapService.map.on('click', LAYERS.custom_workpaces, workplaceClickCallback);
  });

  window.onmessage = function (event) {
    if (event.data == 'message') {
      console('Message received!', event);
    }
  };
});

onBeforeUnmount(() => {
  mapService.map.off('click', LAYERS.hex, hexClickCallback);
  mapService.map.off('click', LAYERS.workpaces, workplaceClickCallback);
  mapService.map.off('click', LAYERS.custom_workpaces, workplaceClickCallback);
});

// methods

const selectItem = (item) => {
  mapService.map.flyTo({
    center: item.geo,
    zoom: 15,
  });
};

// при mousedown на разделитель включаем подписку, при mouseup выключаем
const initDivider = (divider) => {
  let isActivated = false;

  /** движение */
  const mouseMoveListener = (e) => {
    if (!isActivated) return;

    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;

    const height = e.pageY > 100 ? e.pageY : 0;
    containerRef.value.style.setProperty('grid-template-rows', `${height}px auto`);
  };

  /** на mousedown подписываемся на движение */
  const clickListener = () => {
    isActivated = true;

    document.body.addEventListener('mousemove', mouseMoveListener);
    document.body.addEventListener('mouseup', mouseUpListener);
  };

  /** на mouseup отписываемся от движения */
  const mouseUpListener = () => {
    if (!isActivated) return;

    isActivated = false;
    document.body.removeEventListener('mousemove', mouseMoveListener);
    document.body.removeEventListener('mouseup', mouseUpListener);

    hexUpdate$.next(true);
  };

  divider.value.addEventListener('mousedown', clickListener);
}; // тут бы отписываться от этого при дестрое компонента, но хз надо ли - умрёт компонент, умрут все его дети и подписки
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.content {
  display: grid;

  grid-template-areas:
    '.'
    '.';
  grid-template-rows: 60vh auto;

  height: calc(100vh);
  width: 100vw;
  overflow: hidden;
}

.map {
  border: 0;
  width: 100%;
  height: 100%;
}

.z-index {
  &__header {
    z-index: 8;
  }
  &__legend {
    z-index: 20;

    position: absolute;

    margin-bottom: $headerBorderRadius;
    right: $headerBorderRadius;
    border-radius: $headerBorderRadius;
  }
  &__search {
    position: absolute;
    z-index: 8;
    right: 0;
    margin: 8px;
  }
  &__filter {
    position: absolute;
    left: 0;
    top: 0;
    margin: 8px;
  }
  &__table {
    z-index: 7;
  }
}
</style>
