import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import PrimeVue from 'primevue/config';

export const configPrime = (app) => {
  const primaryColor = 'yellow';

  const preset = definePreset(Aura, {
    semantic: {
      primary: {
        50: `{${primaryColor}.50}`,
        100: `{${primaryColor}.100}`,
        200: `{${primaryColor}.200}`,
        300: `{${primaryColor}.300}`,
        400: `{${primaryColor}.400}`,
        500: `{${primaryColor}.500}`,
        600: `{${primaryColor}.600}`,
        700: `{${primaryColor}.700}`,
        800: `{${primaryColor}.800}`,
        900: `{${primaryColor}.900}`,
        950: `{${primaryColor}.950}`,
      },
    },
  });

  app.use(PrimeVue, {
    theme: {
      preset,
      options: {
        darkModeSelector: false || 'none',
      },
    },
  });

};
