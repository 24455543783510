<template>
  <Card>
    <template #content>
      <div>
        <DropdownListComponent
          :title="store.state.currentRegion?.name || 'Регион'"
          :show-reset-button="Boolean(store.state.currentRegion)"
          :items="store.state.regionsList"
          :get-item-text="(region) => region.name"
          @select="(region) => store.commit(MUTATION_SET_CURRENT_REGION, region.id)"
          @reset="() => store.commit(MUTATION_RESET_CURRENT_REGION)"
        ></DropdownListComponent>

        <DropdownListComponent
          :title="store.state.currentTown?.name || 'Город'"
          :show-reset-button="Boolean(store.state.currentTown)"
          :items="store.state.towns?.[store.state.currentRegion?.id]?.list ?? []"
          :get-item-text="(town) => town.name"
          @select="(town) => store.commit(MUTATION_SET_CURRENT_TOWN, town.id)"
          @reset="() => store.commit(MUTATION_RESET_CURRENT_TOWN)"
        ></DropdownListComponent>

        <DropdownListComponent
          :title="store.state.currentHexType?.text"
          :title-icon="store.state.currentHexType?.isHeatmapHex ? 'bi-map' : 'bi-hexagon'"
          :items="store.state.hexTypes"
          :item-icon="(type) => (type.isHeatmapHex ? 'bi-map' : 'bi-hexagon')"
          :get-item-text="(type) => type.text"
          @select="(type) => store.commit(MUTATION_SET_CURRENT_HEX_TYPE, type)"
        ></DropdownListComponent>

        <DropdownListComponent
          :title="store.state.currentProjectGroup?.name ?? 'Группа проектов'"
          :show-reset-button="Boolean(store.state.currentProjectGroup)"
          :items="store.state.projectGroups"
          :get-item-text="(group) => group.name"
          :use-active-field="true"
          @select="(group) => store.commit(MUTATION_SET_CURRENT_PROJECT_GROUP, group.id)"
          @reset="() => store.commit(MUTATION_RESET_CURRENT_PROJECT_GROUP)"
        ></DropdownListComponent>
        <DropdownListComponent
          :title="store.state.currentProject?.name ?? 'Проект'"
          :show-reset-button="Boolean(store.state.currentProject)"
          :items="store.state.projects[store.state.currentProjectGroup?.id]"
          :get-item-text="(project) => project.name"
          :use-active-field="true"
          @select="(project) => store.commit(MUTATION_SET_CURRENT_PROJECT, project.id)"
          @reset="() => store.commit(MUTATION_RESET_CURRENT_PROJECT)"
        ></DropdownListComponent>

        <div class="form-check" v-for="item in store.state.allContracts" :key="item">
          <input class="form-check-input btn-secondary" type="checkbox" v-model="selectedContractTypes" :value="item.id" :id="item.id" />
          <label class="form-check-label btn-secondary" :for="item.id"> {{ item.name }} </label>
        </div>

        <div class="d-flex flex-column">
          <VDatePicker trim-weeks borderless color="gray" v-model="dateStart" :popover="popover" mode="date">
            <template #default="{ togglePopover }">
              <button class="btn map-navbar__second-row__item" type="button" @click="togglePopover">От {{ formatDate(dateStart) }}</button>
            </template>
          </VDatePicker>

          <VDatePicker trim-weeks borderless color="gray" v-model="dateEnd" :popover="popover" mode="date">
            <template #default="{ togglePopover }">
              <button class="btn map-navbar__second-row__item" type="button" @click="togglePopover">До {{ formatDate(dateEnd) }}</button>
            </template>
          </VDatePicker>
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup>
import {
  MUTATION_RESET_CURRENT_PROJECT,
  MUTATION_RESET_CURRENT_PROJECT_GROUP,
  MUTATION_RESET_CURRENT_REGION,
  MUTATION_RESET_CURRENT_TOWN,
  MUTATION_SET_CURRENT_CONTRACT_TYPE,
  MUTATION_SET_CURRENT_HEX_TYPE,
  MUTATION_SET_CURRENT_PROJECT,
  MUTATION_SET_CURRENT_PROJECT_GROUP,
  MUTATION_SET_CURRENT_REGION,
  MUTATION_SET_CURRENT_TOWN,
  MUTATION_SET_DATE_END,
  MUTATION_SET_DATE_START,
} from '@/store/store';
import Card from 'primevue/card';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import DropdownListComponent from './DropdownListComponent.vue';

const store = useStore();

const popover = ref({
  visibility: 'click',
  placement: 'left-start',
});

const selectedContractTypes = ref([...store.state.contract]);

const dateStart = ref(new Date(store.state.dateStart));
const dateEnd = ref(new Date(store.state.dateEnd));

const formatDate = (date) => date.toJSON().split('T')[0];

watch(selectedContractTypes, (contract) => {
  store.commit(MUTATION_SET_CURRENT_CONTRACT_TYPE, contract);
});

watch(dateStart, (date) => {
  store.commit(MUTATION_SET_DATE_START, date);
});
watch(dateEnd, (date) => {
  store.commit(MUTATION_SET_DATE_END, date);
});
</script>

<style lang="scss">
.p-card {
  width: 240px;
}
</style>
