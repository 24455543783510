import { env } from '@/env/env';
import { fileService } from '@/services/FileService';
import { httpService } from '@/services/HttpService';
import { mapService } from '@/services/MapService';
import { CUSTOM_WORKPLACE_STORAGE_KEY, fitBbox } from '@/core/utils';
import { filter, from, interval, Subject, switchMap, takeUntil } from 'rxjs';

export const mShowTokenInfo = (state, tokenInfo) => {
  state.tokenInfo = tokenInfo;
  fileService.toggleTokenModal();
};

export const mAddNewToken = (state, tokenInfo) => {
  state.tokens.push(tokenInfo);
  state.tokenInfo = tokenInfo;
  state.selectedToken = tokenInfo.token;

  saveTokens(state);

  mUseToken(state, tokenInfo);
};

export const mForgotToken = (state, token) => {
  state.tokens = state.tokens.filter((t) => t.token !== token);
  if (state.selectedToken) {
    updateTableData(state, [], false);
  }
  saveTokens(state);
};

export const mUseToken = (state, tokenInfo) => {
  const getDataHook$ = new Subject();

  interval(600)
    .pipe(
      takeUntil(getDataHook$),
      switchMap(() =>
        from(
          httpService
            .get(`${env.api.custom_workplaces}${tokenInfo.token}/progress/`)
            .catch((e) => console.log('Ошибка в запросе, ответ пока не готов', e))
        )
      ),
      filter(Boolean)
    )
    .subscribe(({ data }) => {
      getDataHook$.next();

      const oldTokens = state.tokens.filter((t) => t.token !== tokenInfo.token);
      const { wps, ...updatedToken } = data;

      oldTokens.push(updatedToken);

      state.tokens = oldTokens;
      state.selectedToken = updatedToken.token;

      saveTokens(state);

      if (data.done) {
        updateTableData(state, wps, true);
      }
    });
};

export const mUpdateTableData = (state) => {
  if (!state.currentRegion && !state.currentTown) {
    clearWorkspaceData(state);
  }

  httpService
    .post(env.api.workplaces, {
      region_id: state.currentRegion?.id,
      town_id: state.currentTown?.id,
    })
    .then(({ data }) => {
      updateTableData(state, data);
    })
    .catch(() => {
      clearWorkspaceData(state);
    });
};

const saveTokens = (state) => {
  localStorage.setItem(CUSTOM_WORKPLACE_STORAGE_KEY, JSON.stringify(state.tokens));
};

const clearWorkspaceData = (state) => {
  state.tableData = [];
  state.tableFields = [];
  mapService.updateWorkplaceSource([]);
  mapService.updateCustomWorkplaceSource([]);
};

const updateTableData = (state, data, isCustomWorkplaceSet = false) => {
  state.workplacesTableData = data;

  state.tableData = data.workplaces;
  state.tableFields = data.order;

  if (!isCustomWorkplaceSet) {
    state.selectedToken = null;
    mapService.updateWorkplaceSource(data.workplaces || []);
    mapService.updateCustomWorkplaceSource([]);
  }

  if (isCustomWorkplaceSet) {
    fitBbox(data.bbox);

    state.currentRegion = null;
    state.currentTown = null;
    state.currentProjectGroup = null;
    state.currentProject = null;
    mapService.updateCustomWorkplaceSource(data.workplaces || []);
  }
};
