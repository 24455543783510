import { mapService } from '@/services/MapService';
import { legendUpdate$, projectsUpdate$, tableUpdate$ } from '@/services/UpdateHooks';
import { CUSTOM_WORKPLACE_STORAGE_KEY } from '@/core/utils';
import { createStore } from 'vuex';
import { mSetCurrentContact, mSetDateEnd, mSetDateStart } from './mutations/m_filters';
import { mFetchHexTypes, mFetchLegend, mSetCurrentHexType } from './mutations/m_hex';
import {
  mFetchProjectGeoups,
  mResetCurrentProject,
  mResetCurrentProjectGroup,
  mSetCurrentProject,
  mSetCurrentProjectGroup,
} from './mutations/m_projects';
import { mFetchRegions, mResetCurrentRegion, mResetCurrentTown, mSetCurrentRegion, mSetCurrentTown } from './mutations/m_regions';
import { mAddNewToken, mForgotToken, mShowTokenInfo, mUpdateTableData, mUseToken } from './mutations/m_workplaces';

// если не ставить +12 часов, то дата пересчитывается на местное время браузера, и может получиться так, что выбрано 22 число, а показывается 21
// TODO сделать умнее

// const dateStart = new Date(2024, 3, 12, 12);
// const dateEnd = new Date(2024, 3, 28, 12);
const dateStart = new Date();
dateStart.setDate(dateStart.getDate() - 14);
const dateEnd = new Date();

const initialState = {
  regions: {}, // словарик
  /** {id: String, name: String}[] */
  regionsList: [],
  /** {id: String, name: String} */
  currentRegion: null,

  towns: {}, // словарик
  /** {id: String, name: String} */
  currentTown: null,

  workplacesTableData: {},
  tableData: [],
  tableFields: [],

  /** {key: String, text: String, isHeatmapHex: boolean}[] */
  hexTypes: [],
  /** {key: String, text: String, isHeatmapHex: boolean} */
  currentHexType: null,

  rawLegend: {}, // [zoom]: {gigers: [[0, #fff]]}
  currentHexZoom: null,
  currentLegend: [],

  contract: ['services', 'saas'],
  allContracts: [
    { id: 'services', name: 'Платформа +' },
    { id: 'saas', name: 'Платформа' },
    { id: 'processing', name: 'Платформа PRO' },
  ],

  /** дата в фильтре */
  dateStart,
  dateEnd,

  projectGroups: [],
  currentProjectGroup: null,
  projects: {},
  currentProject: null,

  /** инфо о загруженных файлах */
  tokens: JSON.parse(localStorage.getItem(CUSTOM_WORKPLACE_STORAGE_KEY)) || [],

  /** Для отображения информ модалки */
  tokenInfo: null,

  /** Для понимания, данные в табличке сейчас из кастомного воркплейса, или нет */
  selectedToken: null,
};

export const MUTATION_FETCH_REGIONS = 'fetchRegions';
export const MUTATION_SET_CURRENT_REGION = 'setCurrentRegion';
export const MUTATION_SET_CURRENT_TOWN = 'setCurrentTown';
export const MUTATION_RESET_CURRENT_REGION = 'resetRegion';
export const MUTATION_RESET_CURRENT_TOWN = 'resetTown';
export const MUTATION_UPDATE_TABLE_DATA = 'updateTable';

export const MUTATION_FETCH_HEX_TYPES = 'fetchHexTypes';
export const MUTATION_SET_CURRENT_HEX_TYPE = 'setCurrentHexType';

export const MUTATION_FETCH_LEGEND = 'fetchLEgend';

export const MUTATION_SET_CURRENT_CONTRACT_TYPE = 'setContractType';
export const MUTATION_SET_DATE_START = 'setDateStart';
export const MUTATION_SET_DATE_END = 'setDateEnd';

export const MUTATION_FETCH_PROJECT_GROUPS = 'fetchProjectsGroups';
export const MUTATION_SET_CURRENT_PROJECT_GROUP = 'getCurrentProjectGroup';
export const MUTATION_SET_CURRENT_PROJECT = 'getCurrentProject';
export const MUTATION_RESET_CURRENT_PROJECT_GROUP = 'resetProjectGroup';
export const MUTATION_RESET_CURRENT_PROJECT = 'resetProject';

export const MUTATION_ADD_TOKEN = 'addToken';
export const MUTATION_USE_TOKEN = 'useToken';
export const MUTATION_FORGOT_TOKEN = 'forgotToken';
export const MUTATION_SHOW_TOKEN = 'showToken';

export const ACTION_INIT_BEFORE_MAP = 'initBeforeMap';
export const ACTION_INIT_AFTER_MAP = 'initAfterMap';

export const store = createStore({
  state() {
    return initialState;
  },
  mutations: {
    [MUTATION_FETCH_REGIONS]: mFetchRegions,
    [MUTATION_SET_CURRENT_REGION]: mSetCurrentRegion,
    [MUTATION_SET_CURRENT_TOWN]: mSetCurrentTown,
    [MUTATION_RESET_CURRENT_REGION]: mResetCurrentRegion,
    [MUTATION_RESET_CURRENT_TOWN]: mResetCurrentTown,
    [MUTATION_UPDATE_TABLE_DATA]: mUpdateTableData,

    [MUTATION_SET_CURRENT_HEX_TYPE]: mSetCurrentHexType,
    [MUTATION_FETCH_HEX_TYPES]: mFetchHexTypes,

    [MUTATION_FETCH_LEGEND]: mFetchLegend,

    [MUTATION_SET_CURRENT_CONTRACT_TYPE]: mSetCurrentContact,
    [MUTATION_SET_DATE_START]: mSetDateStart,
    [MUTATION_SET_DATE_END]: mSetDateEnd,

    [MUTATION_FETCH_PROJECT_GROUPS]: mFetchProjectGeoups,
    [MUTATION_SET_CURRENT_PROJECT_GROUP]: mSetCurrentProjectGroup,
    [MUTATION_SET_CURRENT_PROJECT]: mSetCurrentProject,
    [MUTATION_RESET_CURRENT_PROJECT_GROUP]: mResetCurrentProjectGroup,
    [MUTATION_RESET_CURRENT_PROJECT]: mResetCurrentProject,

    [MUTATION_ADD_TOKEN]: mAddNewToken,
    [MUTATION_USE_TOKEN]: mUseToken,
    [MUTATION_FORGOT_TOKEN]: mForgotToken,
    [MUTATION_SHOW_TOKEN]: mShowTokenInfo,
  },
  actions: {
    [ACTION_INIT_BEFORE_MAP](context) {
      context.commit(MUTATION_FETCH_REGIONS);
      context.commit(MUTATION_FETCH_HEX_TYPES);
      context.commit(MUTATION_FETCH_PROJECT_GROUPS);
    },

    [ACTION_INIT_AFTER_MAP](context) {
      context.commit(MUTATION_UPDATE_TABLE_DATA);
      context.commit(MUTATION_FETCH_LEGEND, mapService.map.getZoom());
    },
  },
  getters: {
    filters(state) {
      return {
        date_from: state.dateStart?.toJSON().split('T')[0],
        date_to: state.dateEnd?.toJSON().split('T')[0],
        contract_type: state.contract,
        region_id: state.currentRegion?.id,
        town_id: state.currentTown?.id,
        group_id: state.currentProjectGroup?.id,
        project_id: state.currentProject?.id,
      };
    },
  },
});

window.store = store;

legendUpdate$.subscribe(() => store.commit(MUTATION_FETCH_LEGEND, true));
tableUpdate$.subscribe(() => store.commit(MUTATION_UPDATE_TABLE_DATA));
projectsUpdate$.subscribe(() => store.commit(MUTATION_FETCH_PROJECT_GROUPS));
